import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import logo from "../images/Lancelot PianoB.png"
import styled from "styled-components"
import SEO from "../components/seo"
import ReactMarkdown from "react-markdown"

const Mentions = () => {
  const data = useStaticQuery(graphql`
    query {
      allDatoCmsCgu {
        edges {
          node {
            title
            description
            article1
            article2
            article3
            article4
            article5
            article6
            article7
            article8
            descart1
            descart2
            descart3
            descart4
            descart5
            descart6
            descart7
            descart8
          }
        }
      }
    }
  `)
  const [CGU] = useState(data.allDatoCmsCgu.edges[0].node)
  return (
    <Layout logo={logo} color="#333" menu="Accueil" lien="/">
      <SEO title="Cours de Piano. Cours particuliers de piano à domicile sur Toulon et ses environs. Tout niveau, enfants-ados-adultes | Mentions Legales" />
      <h1 style={{ textAlign: "center", marginTop: "3rem" }}>{CGU.title}</h1>
      <CguWrapper>
        <ReactMarkdown source={CGU.description} />
        <h2>{CGU.article1}</h2>
        <ReactMarkdown source={CGU.descart1} />
        <h2>{CGU.article2}</h2>
        <ReactMarkdown source={CGU.descart2} />
        <h2>{CGU.article3}</h2>
        <ReactMarkdown source={CGU.descart3} />
        <h2>{CGU.article4}</h2>
        <ReactMarkdown source={CGU.descart4} />
        <h2>{CGU.article5}</h2>
        <ReactMarkdown source={CGU.descart5} />
        <h2>{CGU.article6}</h2>
        <ReactMarkdown source={CGU.descart6} />
        <h2>{CGU.article7}</h2>
        <ReactMarkdown source={CGU.descart7} />
        <h2>{CGU.article8}</h2>
        <ReactMarkdown source={CGU.descart8} />
      </CguWrapper>
    </Layout>
  )
}

const CguWrapper = styled.div`
  width: 80%;
  height: auto;
  margin: 5rem auto;

  h1 {
    font-family: "Roboto Condensed", sans-serif;
  }

  h2 {
    text-align: center;
    font-family: "Roboto Condensed", sans-serif;
  }
`

export default Mentions
